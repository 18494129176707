import { Injectable, computed, inject, signal } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Role } from '@shared/types/generic.types';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { environment } from 'src/environments/environment';

const AUTH_SETTINGS: UserManagerSettings = {
  authority: environment.auth.issuer,
  client_id: environment.auth.clientId,
  redirect_uri: `${environment.origin}login-callback`,
  silent_redirect_uri: `${environment.origin}silent-callback.html`,
  popup_post_logout_redirect_uri: `${environment.origin}logout-callback.html`,
  monitorSession: true,
  response_type: 'code',
  userStore: new WebStorageStateStore({ store: sessionStorage }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #router = inject(Router);
  #currentUser = signal<User | null>(null);
  #userManager = new UserManager(AUTH_SETTINGS);
  currentUser = computed(() => this.#currentUser());
  isLoggedIn = computed(() => !!this.currentUser());
  isAdmin = computed(() => this.#isRole('admin'));
  isCoach = computed(() => this.#isRole('coach'));
  isPlayer = computed(() => this.#isRole('player'));

  constructor() {
    this.#userManager.events.addUserLoaded((user: User) => {
      this.#currentUser.set(user);
    });
    this.#userManager.events.addUserUnloaded(() => {
      this.#currentUser.set(null);
      this.#router.navigate(['/login']);
    });
    this.#userManager.events.addUserSignedOut(() => {
      this.#userManager.removeUser();
    });

    this.#userManager.events.addAccessTokenExpiring(() => {
      const u = this.#currentUser();
      // Left here to track down potential causes for errors
      // eslint-disable-next-line no-console
      console.debug(`Access token expiring in ${u?.expires_in}s (${u?.expires_at}).`, u?.expired);
    });

    this.#userManager.events.addAccessTokenExpired(() => {
      const u = this.#currentUser();
      // Left here to track down potential causes for errors
      // eslint-disable-next-line no-console
      console.debug(`Access token expired ${u?.expires_in}s ago (${u?.expires_at}). Trying to manually refresh.`);
      this.signinSilent();
    });
  }

  #isRole(role: Role): boolean {
    return (this.currentUser()?.profile?.['roles'] as Role[])?.includes(role);
  }

  isOnlyRoles(role: Role[]): boolean {
    return (this.currentUser()?.profile?.['roles'] as Role[])?.every(r => role.includes(r));
  }

  async signinSilent(): Promise<void | User | null> {
    return this.#userManager.signinSilent().catch(reason => {
      switch (reason.error) {
        case 'invalid_grant':
          this.#userManager.removeUser();
          break;
        case 'login_required':
          break;
        default:
          // Left here to track down potential causes for errors
          // eslint-disable-next-line no-console
          console.debug('Silent signin failed:', reason.error, reason);
          break;
      }
    });
  }

  signinRedirectCallback(): Promise<User> {
    return this.#userManager.signinRedirectCallback();
  }

  async initializeAuth(): Promise<void> {
    const user = await this.#userManager.getUser();
    if (user) {
      this.#currentUser.set(user);
      return;
    }
    if (window.location.pathname !== '/login-callback') {
      await this.signinSilent();
      return;
    }
  }

  async logIn(redirectTo?: string, extraParams?: Record<string, string>): Promise<void> {
    return this.#userManager.signinRedirect({
      state: { redirect: redirectTo, ...extraParams },
      // TODO: .env instead of localStorage? LocalStorage feels pretty reasonable for this case though.
      login_hint: localStorage.getItem('login_hint') ?? '',
    });
  }

  async logOut(route = '/login', navigationExtra?: NavigationExtras): Promise<boolean> {
    await this.#userManager.signoutSilent();
    return this.#router.navigate([route], navigationExtra);
  }
}
