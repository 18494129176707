import { NgClass, NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { StaggeredAnimationDirective } from '@shared/directives/staggered-animation.directive';
import { ClickMenuItemComponent } from '../click-menu-item/click-menu-item.component';
import { CLICK_MENU_DEFAULT_CONFIGURATION, ClickMenuConfiguration, ClickMenuItemGroup } from '../click-menu.types';

@Component({
  selector: 'coach-grouped-click-menu',
  standalone: true,
  imports: [NgClass, NgStyle, ClickMenuItemComponent, StaggeredAnimationDirective, TranslocoDirective],
  templateUrl: './grouped-click-menu.component.html',
  styleUrls: ['./grouped-click-menu.component.scss'],
})
export class GroupedClickMenuComponent {
  items = input.required<ClickMenuItemGroup[]>();
  config = input<ClickMenuConfiguration, ClickMenuConfiguration>(CLICK_MENU_DEFAULT_CONFIGURATION, {
    transform: (config: ClickMenuConfiguration) => ({ ...CLICK_MENU_DEFAULT_CONFIGURATION, ...config }),
  });
}
