import { Component, input, model } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { BadgeComponent } from '../badge/badge.component';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import { SearchBarComponent } from './search-bar/search-bar.component';

type SearchType = 'sessions' | 'assignments' | 'drills' | 'players';
@Component({
  selector: 'coach-search',
  standalone: true,
  imports: [SearchBarComponent, BadgeComponent, IconComponent, ButtonComponent, TranslocoDirective],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent {
  type = input.required<SearchType>();
  placeholder = input<string>('Find something...');
  filters = input<boolean>(true);
  sorting = input<boolean>(false);

  query = model<string>();
}
