import { DialogModule, DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CloseDialogStatus } from '@shared/types/generic.types';

@Component({
  selector: 'coach-dialog',
  standalone: true,
  imports: [DialogModule, ButtonComponent, NgClass],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent {
  dialogRef = inject(DialogRef<CloseDialogStatus>);
  title = input<string>();
  divider = input<boolean>(true);
  closeButton = input<boolean>(true);
}
