import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, inject, input, OnInit } from '@angular/core';
import { ToastData } from '@shared/services/toast.service';
import { ActionType } from '@shared/types/generic.types';
import { IconComponent, IconName } from '../icon/icon.component';

@Component({
  selector: 'coach-toast',
  standalone: true,
  imports: [IconComponent, NgTemplateOutlet],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  data: ToastData = inject(DIALOG_DATA);
  type = input<ActionType>();

  @HostBinding('class') animationClass = 'dialog-slide-in';

  constructor(
    private dialogRef: DialogRef<ToastComponent>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    setTimeout(() => {
      this.animationClass = 'dialog-slide-out';
      this.cdr.detectChanges();

      setTimeout(() => {
        this.dialogRef.close();
      }, 300);
    }, 3000);
  }

  getIconName(): IconName {
    switch (this.data.type) {
      case 'positive':
        return 'check_circle';
      case 'negative':
        return 'alert_circle';
      case 'warning':
        return 'alert_triangle';
      case 'info':
        return 'alert_circle';
      default:
        return 'alert_circle';
    }
  }
}
