import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { ActionType } from '@shared/types/generic.types';
import { ToastComponent } from '@shared/ui/toast/toast.component';

export type ToastData = { type: ActionType; title: string; description?: string };

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  dialog = inject(Dialog);

  openToast(data: ToastData) {
    this.dialog.open(ToastComponent, {
      data,
      disableClose: true,
      hasBackdrop: false,
    });
  }
}
