import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ActionType, HorizontalAnchorPoint } from '@shared/types/generic.types';
import { CircularSpinnerComponent } from '../circular-spinner/circular-spinner.component';
import { IconComponent, IconName, IconSize } from '../icon/icon.component';

export type ButtonType = ActionType | 'black' | 'white';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'contained' | 'outlined' | 'text';

const SizeConversion: Record<ButtonSize, IconSize> = {
  small: 'xs',
  medium: 's',
  large: 'm',
};

@Component({
  selector: 'coach-button',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, IconComponent, CircularSpinnerComponent, RouterLink],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  variant = input<ButtonVariant>('contained');
  type = input<ButtonType>('primary');
  size = input<ButtonSize>('medium');
  loading = input<boolean>(false);
  disabled = input<boolean>(false);
  text = input<string>();
  textClass = input<string | string[]>();
  link = input<string>();
  navigation = input<string | (string | number)[]>();
  // TODO: Consolidate IconSettings across the board?
  icon = input<IconName>();
  iconPosition = input<HorizontalAnchorPoint>('left');
  iconSize = input<IconSize>();
  convertedIconSize = computed<IconSize>(() => {
    const iconSize = this.iconSize();
    const size = this.size();

    return iconSize ? iconSize : SizeConversion[size];
  });
}
