import { effect, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filterCollectionOnQuery } from '@shared/helpers';
import { NoteBodyType, NoteType, PlayerBase, PlayerInvite, PlayerList, PlayerOverview } from '@shared/types/user.types';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { ApiService } from './api.service';

const EndpointConversion: Record<NoteType, string> = {
  goal: 'goal',
  remark: 'internal-remark',
};

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  #api = inject(ApiService);

  playerId = signal<string | undefined>('');
  #playerId$ = toObservable(this.playerId);
  player$ = this.#playerId$.pipe(
    filter(id => !!id && id.length > 0),
    distinctUntilChanged(),
    switchMap(id => this.#api.getViewModel<PlayerOverview>(`players/${id}`)),
    shareReplay(1)
  );

  #playerListQuery = new BehaviorSubject('');
  #playerListQuery$ = this.#playerListQuery.asObservable().pipe(debounceTime(250), distinctUntilChanged());

  #playerNoteQuery = new Subject<[NoteType, string]>();
  #playerNoteQuery$ = this.#playerNoteQuery.asObservable().pipe(
    debounceTime(1000),
    map(v => {
      return [v[0], v[1].trim()] as [NoteType, string];
    }),
    distinctUntilChanged()
  );

  playerNote$ = this.#playerNoteQuery$.pipe(
    switchMap(([type, text]) =>
      this.#api.putViewModel<NoteBodyType, PlayerOverview>(`players/${this.playerId()}/${EndpointConversion[type]}`, {
        [type]: text,
      })
    )
  );

  #refresh = new Subject<void>();

  players$ = this.#refresh.pipe(
    startWith(undefined),
    switchMap(() => this.#api.get<PlayerBase[]>('players').pipe(shareReplay(1)))
  );
  invites$ = this.#refresh.pipe(
    startWith(undefined),
    switchMap(() => this.#api.get<PlayerInvite[]>('users/invites').pipe(shareReplay(1)))
  );
  playerList$: Observable<PlayerList> = combineLatest([this.#playerListQuery$, this.players$, this.invites$]).pipe(
    map(([query, players, invites]) => ({
      linked: filterCollectionOnQuery(players, 'profile_name', query),
      invited: filterCollectionOnQuery(invites, 'name', query),
    })),
    shareReplay(1)
  );

  activePlayers = toSignal(
    this.#refresh.pipe(
      startWith(undefined),
      switchMap(() => this.#api.get<PlayerOverview[]>(`active-users`).pipe(shareReplay(1)))
    )
  );

  // TODO: Remove this when the endpoint starts working
  ap = effect(() => {
    console.log('activePlayers', this.activePlayers());
  });

  filterPlayerList(query: string): void {
    this.#playerListQuery.next(query);
  }

  refreshPlayerList(): void {
    this.#refresh.next();
  }

  updatePlayerNote(type: NoteType, text: string): void {
    this.#playerNoteQuery.next([type, text]);
  }
}
