<button class="menu-item" [ngClass]="item().class" (click)="item().action?.()">
  @if (item().image) {
    <img class="image" [src]="item().image" alt="Image for the menu item()." />
  }
  <div class="title-container" [ngClass]="'icon-' + item().iconPosition">
    @if (item().icon) {
      <coach-icon [icon]="item().icon!" [size]="item().iconSize ?? 's'" />
    }
    <span class="title" *transloco="let t">
      {{ item().i18n ? t(item().i18n!) : item().title }}
    </span>
  </div>
  @if (item().subtitle) {
    <span class="subtitle">{{ item().subtitle }}</span>
  }
</button>
