<coach-search-bar class="search-bar" [(query)]="query" [placeholder]="placeholder()" />
@if (filters() || sorting()) {
  <div class="filter-buttons" *transloco="let t; read: 'sessions'">
    @if (filters()) {
      <coach-button class="filter-button" variant="outlined" type="black" icon="filter_outlined" [text]="t('all')">
        <coach-badge class="badge-color" [count]="5" />
      </coach-button>
    }
    @if (sorting()) {
      <coach-button class="filter-button" variant="outlined" type="black" icon="sort" [text]="t('newest')" />
    }
  </div>
}
