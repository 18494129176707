import { inject, Injectable } from '@angular/core';
import { UserConfiguration } from '@shared/types/user.types';
import { fromEvent, map, shareReplay } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  #api = inject(ApiService);

  documentClick$ = fromEvent<PointerEvent>(document, 'click');
  userConfiguration$ = this.#api.get<UserConfiguration>('users/configuration').pipe(shareReplay(1));
  teamId$ = this.userConfiguration$.pipe(
    map(config => {
      const teams = config.teams || [];
      const coachTeam = teams.find(team => team.role === 'coach');
      return coachTeam?.team_id ?? null;
    })
  );
}
