@if (menuItems() || groupedMenuItems()) {
  <coach-dropdown [groupedMenuItems]="groupedMenuItems()" [menuItems]="menuItems()" [round]="true" [styled]="false">
    <div class="toggle">
      <ng-container *ngTemplateOutlet="avatar" />
      <coach-icon icon="chevron_down" size="m" />
    </div>
  </coach-dropdown>
} @else {
  <ng-container *ngTemplateOutlet="avatar" />
}

<ng-template #avatar>
  <div class="avatar">{{ name() | abbreviation }}</div>
</ng-template>
