import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LoadingModel, viewModelFrom } from '@shared/types/api.types';
import { share } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  #http = inject(HttpClient);

  get<T>(url: string) {
    return this.#http.get<T>(`${API_URL}${url}`).pipe(share());
  }

  getViewModel<T>(url: string) {
    return viewModelFrom<T, HttpErrorResponse>(this.get<T>(url));
  }

  getViewModelSignal<T>(url: string) {
    return toSignal(this.getViewModel<T>(url), { initialValue: { status: 'loading' } as LoadingModel });
  }

  put<T, K>(url: string, body: T) {
    return this.#http.put<K>(`${API_URL}${url}`, body).pipe(share());
  }

  putViewModel<T, K>(url: string, body: T) {
    return viewModelFrom<K, HttpErrorResponse>(this.put<T, K>(url, body));
  }

  post<T, K>(url: string, body?: T) {
    return this.#http.post<K>(`${API_URL}${url}`, body).pipe(share());
  }

  postViewModel<T, K>(url: string, body?: T) {
    return viewModelFrom(this.post<T, K>(url, body));
  }

  delete<T>(url: string) {
    return this.#http.delete<T>(`${API_URL}${url}`).pipe(share());
  }

  deleteViewModel<T>(url: string) {
    return viewModelFrom(this.delete<T>(url));
  }
}
