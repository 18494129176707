<div class="dialog">
  <div class="header">
    <h2 class="main-title">{{ title() }}</h2>
    @if (closeButton()) {
      <coach-button (click)="dialogRef.close('close')" variant="text" type="black" icon="x" />
    }
  </div>
  <div class="content" [class.divider]="divider()">
    <ng-content />
  </div>

  <div class="footer">
    <ng-content select="left-button" />
    <ng-content select="right-button" />
  </div>
</div>
