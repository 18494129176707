import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { IconComponent } from '@shared/ui/icon/icon.component';
import { ClickMenuItem } from '../click-menu.types';

@Component({
  selector: 'coach-click-menu-item',
  standalone: true,
  imports: [NgClass, IconComponent, TranslocoDirective],
  templateUrl: './click-menu-item.component.html',
  styleUrl: './click-menu-item.component.scss',
})
export class ClickMenuItemComponent {
  item = input.required<ClickMenuItem>();

  class = input<string>();
}
