import { Component, input } from '@angular/core';

@Component({
  selector: 'coach-spinner',
  standalone: true,
  template: `
    <div class="coach-spinner">
      <div class="wrapper">
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
      </div>
      <div>
        <h3>{{ text() }}</h3>
      </div>
    </div>
  `,
})
export class SpinnerComponent {
  text = input<string>('Loading...');
}
