@if (link()) {
  <a class="link" [href]="link()">
    <ng-template *ngTemplateOutlet="buttonTemplate" />
  </a>
} @else {
  <ng-template *ngTemplateOutlet="buttonTemplate" />
}

<ng-template #buttonTemplate>
  <button
    class="button"
    [routerLink]="navigation()"
    [disabled]="loading() || disabled()"
    [ngClass]="[variant(), type(), size(), 'icon-' + iconPosition()]"
    [class.icon-button]="icon() && !text()"
    [class.loading]="loading()">
    <span class="button-content">
      @if (loading()) {
        <coach-circular-spinner class="loader" />
      }
      @if (icon()) {
        <coach-icon class="icon" [icon]="icon()!" [size]="convertedIconSize()" />
      }
      @if (text()) {
        <span class="text truncate" [ngClass]="textClass()">{{ text() }}</span>
      }
      <ng-content />
    </span>
  </button>
</ng-template>
