import { Component } from '@angular/core';

@Component({
  selector: 'coach-circular-spinner',
  standalone: true,
  template: `
    <svg class="spinner" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00012 1C9.05069 1 10.091 1.20693 11.0616 1.60896C12.0322 2.011 12.9141 2.60028 13.657 3.34315C14.3998 4.08602 14.9891 4.96793 15.3912 5.93853C15.7932 6.90914 16.0001 7.94943 16.0001 9C16.0001 10.0506 15.7932 11.0909 15.3912 12.0615C14.9891 13.0321 14.3998 13.914 13.657 14.6569C12.9141 15.3997 12.0322 15.989 11.0616 16.391C10.091 16.7931 9.05069 17 8.00012 17C6.94954 17 5.90925 16.7931 4.93865 16.391C3.96804 15.989 3.08613 15.3997 2.34326 14.6569" />
    </svg>
  `,
  styles: `
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
    }

    .spinner {
      animation: rotate 1.5s linear infinite;
      display: inline-block;
    }

    .spinner path {
      stroke: currentColor;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  `,
})
export class CircularSpinnerComponent {}
