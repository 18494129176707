<div class="menu" [ngClass]="config().anchorPoint" *transloco="let t">
  <div class="menu-container">
    @for (group of items(); track group.id; let i = $index) {
      <div
        class="menu-group"
        [staggeredAnimation]="{ direction: config().animation, index: i, length: items().length }">
        @if (group.i18n || group.title) {
          <div class="menu-header">{{ group.i18n ? t(group.i18n) : group.title }}</div>
        }
        @for (item of group.items; track item.id) {
          <coach-click-menu-item [item]="item" />
        }
      </div>
    }
  </div>
</div>
