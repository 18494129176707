import { Component, input } from '@angular/core';
import { BadgeComponent } from '../badge/badge.component';

@Component({
  selector: 'coach-active-badge',
  standalone: true,
  host: { '[class.animated]': 'animated()' },
  imports: [BadgeComponent],
  templateUrl: './active-badge.component.html',
  styleUrl: './active-badge.component.scss',
})
export class ActiveBadgeComponent {
  animated = input<boolean>(false);
}
